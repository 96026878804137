//@ts-ignore
import * as SunCalc from "suncalc";
import { useToday } from "../hooks/useToday";
import dayjs from "dayjs";
import { lat, lng } from "../helpers/config";

export function useSunsetSunrise() {
  const { today } = useToday();

  // Pobiedziska
  const { sunrise, sunset } = getSunTimes(today.toDate(), lat, lng);

  return {
    sunrise: dayjs(sunrise).format("HH:mm"),
    sunset: dayjs(sunset).format("HH:mm"),
  };
}

type SunTimes = {
  sunrise: Date;
  sunset: Date;
};

function getSunTimes(date: Date, lat: number, lng: number): SunTimes {
  const sunTimes = SunCalc.getTimes(date, lat, lng);

  return {
    sunrise: sunTimes.sunrise,
    sunset: sunTimes.sunset,
  };
}
