import React, { useState, useEffect } from 'react';

export const StringChanger: React.FC = () => {
    const [currentString, setCurrentString] = useState<string>('Wystawianie koszy...');
    const stringOptions: string[] = ['Liczenie worków...', 'Gniecenie kartonów...', 'Zgniatanie puszek...', 'Segregowanie szkła...', 'Wiązanie worków...'];
    let currentIndex: number = 0;

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentString(stringOptions[currentIndex]);
            // eslint-disable-next-line react-hooks/exhaustive-deps
            currentIndex = (currentIndex + 1) % stringOptions.length;
        }, 1500);

        return () => clearInterval(intervalId);
    }, []);

    return (
        <div>
            <p>{currentString}</p>
        </div>
    );
};