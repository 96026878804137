import dayjs from "dayjs";
import { lightTheme } from "../theme";
import packageJson from "../../package.json";
import styled from "styled-components";
import { buildCode } from "../helpers/config";

export function Copyright() {
  const year = dayjs().format("YYYY");

  return (
    <CopyrightTemplate>
      © Jan Osio, {year} v{packageJson.version}, {buildCode}
    </CopyrightTemplate>
  );
}

const CopyrightTemplate = styled.div`
  display: flex;
  justify-content: center;
  padding: 1rem;
  font-size: 12px;
  color: ${lightTheme.textLight};
`;
