import React, { useMemo } from "react";
import dayjs from "dayjs";
import { Item } from "./Item";
import { Heading } from "@chakra-ui/react";
import { useTheme } from "styled-components";
import { EmptyStateTemplate } from "./EmptyStateTemplate";
import { Dayjs } from "dayjs";
import { If, Then, Else, When } from "react-if";
// import { LocalSchedule } from "../views/Home/LocalSchedule";
// import { useToday } from "../hooks/useToday";

type ScheduleItem = {
  date: string;
  types: string[];
};

type ScheduleListProps = {
  schedule: ScheduleItem[];
  today: Dayjs;
};

export function ScheduleList({ schedule, today }: ScheduleListProps) {
  const theme = useTheme() as any;
  const months: string[] = [];

  // const { monthNumber } = useToday();

  const currentSchedule = useMemo(
    () =>
      schedule.filter(
        (item: ScheduleItem) =>
          dayjs.utc(item.date).diff(today, "days", true) >= -1,
      ),
    [schedule, today],
  );

  const isEmpty = useMemo(
    () => currentSchedule.length === 0,
    [currentSchedule],
  );

  return (
    <If condition={isEmpty}>
      <Then>
        <EmptyStateTemplate />
        {/* <If condition={monthNumber === 1 || monthNumber === 12}>
          <Then>
            <div style={{ paddingTop: "3rem" }}>
              <LocalSchedule />
            </div>
          </Then>
          <Else>
            <EmptyStateTemplate />
          </Else>
        </If> */}
      </Then>
      <Else>
        <div key={today.toString()} style={{ paddingTop: "1rem", minHeight: "calc(100vh - 270px)" }}>
          {currentSchedule.map((item: ScheduleItem) => {
            const currentMonth = dayjs.utc(item.date).format("MMMM");
            const notHaveMonth = !months.includes(currentMonth);

            if (notHaveMonth) {
              months.push(currentMonth);
            }

            return (
              <React.Fragment key={item.date+item.types}>
                <When condition={notHaveMonth}>
                  <Heading
                    key={currentMonth}
                    mt={6}
                    color={theme.text}
                    as="h4"
                    size="xl"
                    fontWeight={300}
                  >
                    {currentMonth}
                  </Heading>
                </When>
                <div key={item.date}>
                  <Item
                    item={item}
                    today={today}
                    borderColor={theme.itemBorderColor}
                    styles={{ opacity: 0.5 }}
                  />
                </div>
              </React.Fragment>
            );
          })}
        </div>
      </Else>
    </If>
  );
}
