import styled from "styled-components";
import {
  Button,
  Container,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
} from "@chakra-ui/react";
import { Title, BigDate, Loader } from "../../components/Styled";
import gearIcon from "../../assets/gear.svg";
import {
  Calendar,
  ScheduleList,
  BuyCoffeeButton,
  Copyright,
  Sun,
  SelectAddressForm,
  StringChanger,
} from "../../components";
import { lightTheme } from "../../theme";
import { useSchedule } from "../../context/ScheduleProvider";
import Snowfall from "react-snowfall";
import { If, Then, Else } from "react-if";
import { useToday } from "../../hooks/useToday";
import { getMonthImage } from "../../helpers/helpers";

// Helper Components
const SettingsButton = ({ onClick }: any) => (
  <Button
    ml={2}
    mt={2}
    size="xs"
    color="#ffffff"
    onClick={onClick}
    backgroundColor="transparent"
  >
    <img
      src={gearIcon}
      width="16"
      height="16"
      style={{ marginRight: "0.25rem" }}
      alt="ustawienia"
    />
    Ustawienia
  </Button>
);

const MainContainer = ({ children, background }: any) => (
  <Container
    maxW="container.md"
    overflow="visible"
    css={{
      position: "relative",
      height: "14rem",
      backgroundImage: background,
      backgroundSize: "cover",
    }}
  >
    {children}
  </Container>
);

const LoaderScreen = () => (
  <Container
    minHeight="calc(100vh - 220px)"
    maxW="container.md"
    overflow="hidden"
    backgroundColor="#edf1f4"
    display="flex"
    flex={1}
    padding="100px 1rem 0px 1rem"
    flexDirection="column"
    justifyContent="space-between"
  >
    <Loader>
      <Spinner mr={2} />
      <StringChanger />
    </Loader>
    <Copyright />
  </Container>
);

const ErrorScreen = () => (
  <>
    <ErrorTemplate>
      Wystąpił błąd po stronie dostawcy danych, Przepraszamy!
    </ErrorTemplate>
    <Copyright />
  </>
);

const ScheduleContainer = ({ schedule, today }: any) => (
  <Container
    maxW="container.md"
    overflow="hidden"
    backgroundColor="#edf1f4"
    display="flex"
    flex={1}
    flexDirection="column"
  >
    {schedule.length > 0 ? (
      <>
        <ScheduleList schedule={schedule} today={today} />
        <Copyright />
      </>
    ) : (
      <div
        style={{
          height: "100vh",
          padding: "3rem",
        }}
      >
        <SelectAddressForm />
      </div>
    )}
  </Container>
);

export function Home() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { today, dayMonth, isDecember, isOctober, monthNumber } = useToday();
  const { isLoading, fullAddress, schedule, resetModalState, error } = useSchedule();

  const handleToggle = () => (isOpen ? onClose() : onOpen());
  const isSnowVisible = isDecember || isOctober;

  const background = `
    linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0) 50%, rgba(0, 0, 0, 0) 100%), 
    url(${getMonthImage(monthNumber)}), 
    linear-gradient(180deg, rgba(43,143,152,1) 0%, rgba(43,143,152,1) 78%, #edf1f4 78%, #edf1f4 100%)
  `;

  return (
    <>
      {isSnowVisible && <Snowfall style={{ zIndex: 1 }} />}
      <MainContainer background={background}>
        <Flex pt={4} alignItems="flex-start" justifyContent="space-between">
          <Flex flexDirection="column">
            <Title>{fullAddress}</Title>
            <BigDate>{dayMonth}</BigDate>
            <Sun />
          </Flex>
          <Flex alignItems="flex-start" justifyContent="flex-end" flexWrap="wrap">
            <BuyCoffeeButton />
            <SettingsButton onClick={handleToggle} />
          </Flex>
        </Flex>
        <Calendar schedule={schedule} today={today} />
      </MainContainer>

      <If condition={isLoading}>
        <Then>
          <If condition={!error}>
            <Then>
              <LoaderScreen />
            </Then>
            <Else>
              <ErrorScreen />
            </Else>
          </If>
        </Then>
        <Else>
          <ScheduleContainer schedule={schedule} today={today} />
        </Else>
      </If>

      <SettingsModal
        isOpen={isOpen}
        onClose={() => {
          onClose();
          resetModalState();
        }}
      />
    </>
  );
}

const SettingsModal = ({ isOpen, onClose }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="sm">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader color={lightTheme.text}>Ustawienia</ModalHeader>
      <ModalCloseButton
        color={lightTheme.text}
        backgroundColor={lightTheme.background2}
      />
      <ModalBody>
        <SelectAddressForm />
      </ModalBody>
      <ModalFooter mt={6} justifyContent="space-between">
        <BuyCoffeeButton />
        <Button
          backgroundColor={lightTheme.background2}
          color={lightTheme.text}
          onClick={onClose}
        >
          Zamknij
        </Button>
      </ModalFooter>
    </ModalContent>
  </Modal>
);

const ErrorTemplate = styled.div`
  display: flex;
  justify-content: center;
  padding: 4rem;
  font-size: 16px;
  color: ${lightTheme.red};
`;
