import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import pl from "dayjs/locale/pl";
import { ThemeProvider } from "styled-components";
import { lightTheme, darkTheme } from "./theme";
import { GlobalStyles } from "./components/GlobalStyles";
import { Cookies } from "./components/Cookies";
// import { News } from "./components/News";
import { useDarkMode } from "./hooks/useDarkMode";
import { Home } from "./views/Home/Home";
import { ScheduleProvider } from "./context/ScheduleProvider";

import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://f0cc0f387df80e54603d701b5101c812@o4508618705993728.ingest.de.sentry.io/4508618709860432",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

dayjs.extend(utc);
dayjs.locale(pl);

function App() {
  const [theme] = useDarkMode();

  return (
    <ThemeProvider theme={theme === "light" ? lightTheme : darkTheme}>
      <ScheduleProvider>
        <GlobalStyles />
        <Home />
        {/* <News name="20241205" title="Drodzy uyżytkownicy" buyCoffe={true}>
          <p>
            Dziękuję Wam za kolejny wspólny rok! Wasze zaangażowanie i opinie
            sprawiły, że aplikacja ciągle się rozwija. Niech nadchodzący rok
            przyniesie nam jeszcze więcej sukcesów i wspaniałych chwil.
          </p>
        </News> */}
        <Cookies />
      </ScheduleProvider>
    </ThemeProvider>
  );
}

export default App;
