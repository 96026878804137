import { useState, useEffect } from "react";
import dayjs, { Dayjs } from "dayjs";

export function useToday() {
  const [today, setToday] = useState<Dayjs>(dayjs().utc());

  const isDecember = today.month() === 11;
  const isOctober = today.month() === 10;

  useEffect(() => {
    const interval = setInterval(() => {
      setToday(dayjs().utc());
    }, 1000 * 60);
    return () => clearInterval(interval);
  }, []);

  const dayMonth = today.format("D MMMM");
  const monthNumber = today.month() + 1; // 1 - 12

  return {
    today,
    dayMonth,
    isDecember,
    isOctober,
    monthNumber,
  };
}
